import React from 'react';
import { Link, graphql, PageProps } from 'gatsby';
import readingTime from 'reading-time';
import { paramCase } from 'param-case';
import { BLOCKS, Block, Inline } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { Options } from '@contentful/rich-text-react-renderer';

import Markdown from '../components/markdown';
import { AuthorPageByIdQuery, BlogPostBySlugQuery } from '../../gatsby-graphql';

import Seo from '../components/seo';
import Layout from '../components/layout';
import Hero from '../components/hero';
import Tags from '../components/tags';
import * as styles from './blog-post.module.scss';

const convertNodeContentToParamCaseStringForAnchor = (node: Block | Inline) => {
  const anchorId = node.content
    .map((t) => {
      if (t.nodeType === 'text') {
        return t.value;
      }
      return '';
    })
    .join();
  if (anchorId) {
    return paramCase(anchorId);
  }
};

const defaultNodeRenderers: Options = {
  renderNode: {
    // [BLOCKS.PARAGRAPH]: (node, children) => <p id={paramCase(node.content.map((v) => v.value))}>{children}</p>,
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1 id={convertNodeContentToParamCaseStringForAnchor(node)}>{children}</h1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2 id={convertNodeContentToParamCaseStringForAnchor(node)}>{children}</h2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 id={convertNodeContentToParamCaseStringForAnchor(node)}>{children}</h3>
    ),
    // [BLOCKS.HEADING_4]: (node, children) => <h4 id={paramCase(node.content)}>{children}</h4>,
    // [BLOCKS.HEADING_5]: (node, children) => <h5 id={paramCase(node.content)}>{children}</h5>,
    // [BLOCKS.HEADING_6]: (node, children) => <h6 id={paramCase(node.content)}>{children}</h6>,
    // [BLOCKS.EMBEDDED_ENTRY]: (node, next) => <div>{next(node.content)}</div>,
    // [BLOCKS.UL_LIST]: (node, next) => <ul>{next(node.content)}</ul>,
    // [BLOCKS.OL_LIST]: (node, next) => <ol>{next(node.content)}</ol>,
    // [BLOCKS.LIST_ITEM]: (node, next) => <li>{next(node.content)}</li>,
    // [BLOCKS.QUOTE]: (node, next) => <blockquote>{next(node.content)}</blockquote>,
    // [BLOCKS.HR]: () => <hr />,
    // [INLINES.ASSET_HYPERLINK]: node => defaultInline(INLINES.ASSET_HYPERLINK, node),
    // [INLINES.ENTRY_HYPERLINK]: node => defaultInline(INLINES.ENTRY_HYPERLINK, node),
    // [INLINES.EMBEDDED_ENTRY]: node => defaultInline(INLINES.EMBEDDED_ENTRY, node),
    // [INLINES.HYPERLINK]: (node, next) => <a href={node.data.uri}>{next(node.content)}</a>,
  },
};

const AuthorPageTemplate: React.FC<PageProps<AuthorPageByIdQuery>> = (props) => {
  const { author } = props.data;
  return (
    <Layout location={props.location}>
      <Seo
        title={author?.name || ''}
        description={''}
        image={
          author?.image?.gatsbyImageData?.resize?.src
            ? `http:${author.image.gatsbyImageData?.resize?.src}`
            : ''
        }
      />
      <Hero
        image={author?.image?.gatsbyImageData}
        title={author?.name || ''}
      // content={post.description}
      />
      <div className={styles.container}>
        <div className={styles.article}>
          <Markdown>{author?.shortBio?.childMdx?.body}</Markdown>
        </div>
      </div>
    </Layout>
  );
};

export default AuthorPageTemplate;

export const pageQuery = graphql`
  query AuthorPageByID($id: String!) {
    author: contentfulPerson(id: { eq: $id }) {
      id
      name
      shortBio {
        childMdx {
          body
        }
      }
      image {
        gatsbyImageData
      }
    }
  }
`;
